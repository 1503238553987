import styled from 'styled-components'

export const Container = styled.div`
  width: 100%;
  height: 80px;
  background: ${props => props.theme.colors.white};
  box-shadow: 0px 14px 21px 0px #EBEBEB; 
`

export const ContainerDesktop = styled.div`
  width: 90%;
  margin: 30px auto 50px;
  line-height: 1.58;
  font-size: 20px;
  text-align: center;
  height: 40px;
  background: ${props => props.theme.colors.white};
  font-family: "ModernEra ExtraBold",serif;
`

export const Image = styled.div`
 padding-top: 24px;
 margin-left: 16px;
`
