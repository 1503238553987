import styled from 'styled-components'

const Photo = styled.div`
  width: 100%;
  height: 100%;
  background-image: url(${props => props.preview});
  -webkit-background-size: cover; /* For WebKit*/
  -moz-background-size: cover;    /* Mozilla*/
  -o-background-size: cover;      /* Opera*/
  background-size: cover;
  background-position: center;
`

export default Photo
