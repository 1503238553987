import React, { useEffect, useState } from 'react'
import { useSnackbar } from 'notistack'
import { useHistory } from 'react-router-dom'
import Steps from '../../components/Steps/Steps'
import styles from './all-set.module.css'
import instanceServer from '../../api/api'
import { LoaderPage, SuccessRedirect } from '../../components'
import check from '../../assets/photo-capture/checkmark.svg'
import { bodyNotification, sendErrorNotification } from '../../api/notifications'
import sleep from '../../utils/sleep/sleep'

const stepData = [
  {
    id: 0,
    text: 'Quiz',
    current: false
  },
  {
    id: 1,
    text: 'Carga de fotos',
    current: true
  }
]

const texts = { ready: '¡Estamos listos!', send: 'Enviar Fotos', again: 'Volver a tomar foto' }

const AllSet = () => {
  const [frontOcclusion] = useState(window.localStorage.getItem('frontOcclusion'))
  const [lowerOcclusion] = useState(window.localStorage.getItem('lowerOcclusion'))
  const [upperOcclusion] = useState(window.localStorage.getItem('upperOcclusion'))
  const [activeFrontO, setActiveFrontO] = useState(false)
  const [redirect, setRedirect] = useState(false)
  const [activeLowerO, setActiveLowerO] = useState(false)
  const [activeUpperO, setActiveUpperO] = useState(false)
  const [diagnostic, setDiagnostic] = useState(null)
  const history = useHistory()
  const { enqueueSnackbar } = useSnackbar()
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    (async function init() {
      setLoading(true)
      let isDiagnostic = JSON.parse(window.localStorage.getItem('diagnostic'))
      if (isDiagnostic) {
        const { data: response } = await instanceServer.post('get-patient', { customerId: isDiagnostic.patientId })
        if (response) {
          window.localStorage.setItem('diagnostic', JSON.stringify(response))
          isDiagnostic = response
          setDiagnostic(response)
          if (response.imagesLoaded && response.imagesLoaded.length > 0) {
            setRedirect(true)
            const milliseconds = 3000
            await sleep(milliseconds)
            if (response.countryOps === 'Colombia' || response.countryOps === 'colombia') {
              window.location.href = `${process.env.REACT_APP_THANKYOU_CO}?mail=${response.email}`
            } else {
              window.location.href = `${process.env.REACT_APP_THANKYOU_MX}?mail=${response.email}`
            }
          }
        }
      }
      if (!frontOcclusion || !lowerOcclusion || !upperOcclusion) {
        if (isDiagnostic && isDiagnostic.patientId) {
          history.push(`/patient/${isDiagnostic.patientId}/stack`)
        } else {
          history.push('/')
        }
      }
    }())
    setLoading(false)
  }, [
    frontOcclusion,
    history,
    lowerOcclusion,
    upperOcclusion
  ])

  const handleNotification = (text) => {
    enqueueSnackbar(text, { variant: 'info', anchorOrigin: { horizontal: 'right', vertical: 'top' } })
  }

  const handleAgain = () => {
    if (!activeFrontO && !activeLowerO && !activeUpperO) {
      handleNotification('Selecciona una foto')
    } else {
      if (activeFrontO) {
        window.localStorage.removeItem('frontOcclusion')
      } else if (activeLowerO) {
        window.localStorage.removeItem('lowerOcclusion')
      } else if (upperOcclusion) {
        window.localStorage.removeItem('upperOcclusion')
      }
      if (diagnostic && diagnostic.patientId) {
        history.push(`/patient/${diagnostic.patientId}/stack`)
      }
    }
  }

  const handleSend = async () => {
    try {
      setLoading(true)
      const {
        phone, email, patientId, countryOps
      } = diagnostic
      const body = {
        customerId: patientId,
        email,
        phone,
        journey: 'Pictures-Appointment',
        photos: [
          {
            url: frontOcclusion,
            name: 'frontOcclusion'
          },
          {
            url: lowerOcclusion,
            name: 'lowerOcclusion'
          },
          {
            url: upperOcclusion,
            name: 'superiorOcclusion'
          }
        ]
      }
      const { data: result } = await instanceServer.post('update-photos', body)
      if (result && result.success) {
        setLoading(false)
        window.dataLayer.push({ event: 'Deal-Photos-Sent-CO' })
        window.fbq('track', 'Photo-Upload-CO')
        setRedirect(true)
        const milliseconds = 3000
        await sleep(milliseconds)
        if (countryOps === 'Colombia' || countryOps === 'colombia') {
          window.location.href = `${process.env.REACT_APP_THANKYOU_CO}?mail=${diagnostic.email}`
        } else {
          window.location.href = `${process.env.REACT_APP_THANKYOU_MX}?mail=${diagnostic.email}`
        }
      }
      setLoading(false)
    } catch (handleSendError) {
      setLoading(false)
      const {
        phone, email, patientId
      } = diagnostic
      const body = {
        customerId: patientId,
        email,
        phone,
        journey: 'Pictures-Appointment',
        photos: [
          {
            url: frontOcclusion,
            name: 'frontOcclusion'
          },
          {
            url: lowerOcclusion,
            name: 'lowerOcclusion'
          },
          {
            url: upperOcclusion,
            name: 'superiorOcclusion'
          }
        ]
      }
      handleNotification('Error al enviar fotos, por favor intenta nuevamente.')
      bodyNotification.text = `#### Front: Pictures platform \n ___ \n
all-set.js file, line 121 catch handleSend function \n
requested to lambda pictures-platform-backend: \n
\`\`\`json
${JSON.stringify(body, null, 2)}
\`\`\`
___ \n
result: \n
\`\`\`json
${JSON.stringify(handleSendError, null, 2)}
\`\`\`
`
      await sendErrorNotification(bodyNotification)
    }
  }

  if (loading) {
    return (
      <LoaderPage />
    )
  }

  if (redirect) {
    return (
      <SuccessRedirect />
    )
  }

  return (
    <div>
      <Steps data={stepData} />
      <p className={styles.ready}>{texts.ready}</p>

      <div
        className={styles.imageWrapper}
        onClick={() => {
          setActiveFrontO(true)
          setActiveLowerO(false)
          setActiveUpperO(false)
        }}
      >
        <div
          className={styles.checkContainer}
        >
          <img
            src={check}
            alt="checkmark"
            className={styles.check}
          />
        </div>
        <div
          className={activeFrontO ? styles.imageActive : styles.image}
          style={{ backgroundImage: `url(${frontOcclusion})` }}
        />
      </div>
      <div
        className={styles.imageWrapper}
        onClick={() => {
          setActiveFrontO(false)
          setActiveLowerO(true)
          setActiveUpperO(false)
        }}
      >
        <div
          className={styles.checkContainer}
        >
          <img
            src={check}
            alt="checkmark"
            className={styles.check}
          />
        </div>
        <div
          className={activeLowerO ? styles.imageActive : styles.image}
          style={{ backgroundImage: `url(${lowerOcclusion})` }}
        />
      </div>
      <div
        className={styles.imageWrapper}
        onClick={() => {
          setActiveFrontO(false)
          setActiveLowerO(false)
          setActiveUpperO(true)
        }}
      >
        <div
          className={styles.checkContainer}
        >
          <img
            src={check}
            alt="checkmark"
            className={styles.check}
          />
        </div>
        <div
          className={activeUpperO ? styles.imageActive : styles.image}
          style={{ backgroundImage: `url(${upperOcclusion})` }}
        />
      </div>

      <div
        onClick={() => handleSend()}
        className={styles.button}
      >
        <p className={styles.textButton}>
          {texts.send}
        </p>
      </div>
      <p
        id={activeFrontO ? 'RecapSelectFrontPhoto' : activeUpperO ? 'RecapSelectUpperPhoto' : activeLowerO ? 'RecapSelectLowerPhoto' : ''}
        onClick={() => handleAgain()}
        className={styles.again}
      >
        {texts.again}
      </p>
    </div>
  )
}

export default AllSet
