import styled from 'styled-components'

export const Container = styled.div`
  width: 100%;
  height: 100vh;
  background: ${props => props.theme.colors.white};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`
export const Image = styled.img`
  width: 92.5px ;
  margin: 20px;
`
