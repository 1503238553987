import styled from 'styled-components'

const Image = styled.img`
  width: 100%;
  max-width: ${props => (props.desktop ? '900px' : '128px')};
  object-fit: contain;
  margin: ${props => (props.desktop ? '0' : '24px 0')};
`

export default Image
