import styled from 'styled-components'

const Text = styled.p`
  font-size: ${props => props.theme.fontSizes[props.size]};
  margin: 5px auto;
  width: 90%;
  text-align: ${props => props.align};
  font-family: ${props => props.theme.fonts[props.type]};
  color: ${props => props.theme.colors[props.color]};
  text-transform: ${props => (props.upper ? 'uppercase' : 'sentence')};
  text-transform: ${props => props.capital && 'capitalize'};
  ${({ line }) => line && `
    line-height: 1.20;
    margin-bottom: 15px;
  `}
`

export default Text
