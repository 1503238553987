/* eslint-disable max-statements */
import axios from 'axios'
import { ErrorException } from '../../utils/error-exception'
import { bytesToSize } from '../../utils/bytes-to-size'
import { changeData } from '../../utils/resize'

const { REACT_APP_BUCKET, REACT_APP_FILE_UPLOAD } = process.env

/**
 * @return {React.FunctionComponent} the main screen of app
 * @param {Object} dataPhoto - object of data
 * @param {string} customerId - patient customerId
 */
const uploadPhoto = async (
  dataPhoto, customerId, imgSrc
) => {
  const newDataPhoto = await changeData(dataPhoto, imgSrc)

  const requested = {
    photoToUpload: {
      name: newDataPhoto.photoToUpload.name,
      size: bytesToSize(newDataPhoto.photoToUpload.size),
      type: newDataPhoto.photoToUpload.type
    },
    photoName: newDataPhoto.photoName
  }
  try {
    let url = ''
    const ext = newDataPhoto.photoToUpload.type.split('/')[1]
    const data = new FormData()
    data.append('File', newDataPhoto.photoToUpload)
    data.append('bucketName', REACT_APP_BUCKET)
    data.append('isPublic', 'true')
    data.append('folderName', `${customerId}_${Math.floor(Date.now())}`)
    data.append('fileName', `${customerId}-${newDataPhoto.photoName}.${ext}`)
    const config = {
      method: 'post',
      url: REACT_APP_FILE_UPLOAD,
      headers: { 'Content-Type': 'multipart/form-data' },
      data
    }
    await axios(config)
      .then((responseUpload) => {
        url = responseUpload.data.file_url
      })
      .catch((errorPost) => {
        throw new ErrorException(
          `error post upload-photo.js file line 29, endpoint: file upload error: ${errorPost}, customerId: ${customerId}`,
          'axios catch',
          { errorPost, requested, customerId }
        )
      })
    if (url) {
      return url
    }
    throw new ErrorException(
      `null url to upload-photo.js file line 40, endpoint: file upload url: ${url}, customerId: ${customerId}`,
      'validate url',
      { url, requested, customerId }
    )
  } catch (errorUploadPhoto) {
    if (errorUploadPhoto && errorUploadPhoto.message) {
      throw new ErrorException(
        errorUploadPhoto.message,
        'upload Photo function file in pictures platform front line 55 endpoint: file upload',
        { errorUploadPhoto: errorUploadPhoto.stack, requested, customerId }
      )
    }
    throw new ErrorException(
      `Error uploading upload-photo.js file line 50, endpoint: file upload, errorUploadPhoto: ${errorUploadPhoto} customerId: ${customerId}`,
      'catch uploadPhoto',
      { errorUploadPhoto, requested, customerId }
    )
  }
}

export default uploadPhoto
