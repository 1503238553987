import styled from 'styled-components'

export const Container = styled.div`
  width: 100%;
  height: 100vh;
  background: rgba(0, 0, 0, .5);
  display: flex;
  align-items: center;
  position: absolute;
  z-index: 4;
`

export const PopUp = styled.div`
  width: 300px;
  height: auto;
  margin-left: auto;
  margin-right: auto;
  padding: 20px;
  border-radius: 21px;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  background-color: #ffffff;
  text-align: center;
`

export const ImageCircle = styled.img`
  width: 150px;
  height: 150px;
  border-radius: 50%;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  background-color: #ffffff;
  margin-top: -90px;
`

export const Message = styled.p`
  font-family: ModernEra Regular;
  font-size: 17px;
  line-height: 1.25;
  text-align: center;
  color: #2e2e2e;
  margin-left:15px;
  margin-right:15px;
`
export const Button = styled.button`
  width: 180px;
  height: 40px;
  border-radius: 6px;
  background-color: #1245f3;
  border: none;
  font-family: ModernEra Regular;
  font-size: 18px;
  font-weight: 800;
  color: #ffffff;
  cursor: pointer;
  &:hover {
    background-color: #2f5eff;
  }
`
