import styled from 'styled-components'

const Container = styled.div`
 width: 90%;
 margin: 30px auto 0;
 height: auto;

`

export default Container
