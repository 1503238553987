import axios from 'axios'

const instanceNotifications = axios.create({
  baseURL: process.env.REACT_APP_SERVER_NOTIFICATIONS,
  headers: {
    Accept: 'application/json',
    Authorization: process.env.REACT_APP_AUTH_NOTIFICATIONS
  }
})

export const bodyNotification = {
  text: '',
  channel: '',
  username: 'Apolo de moons',
  icon_url: 'https://generic-moons-assets.s3.amazonaws.com/avatar_apolo.png'
}

export const sendErrorNotification = async (body) => {
  body.channel = process.env.REACT_APP_CHANNEL_ERROR_NOTIFICATIONS
  return await instanceNotifications.post('mattermost', body)
}
