const themeData = {
  dimensions: {

  },
  colors: {
    white: '#ffffff',
    white49: '#dee5e5',
    background: '#FBFBFB',
    brownGrey: '#9d9d9d',
    brownGrey2: '#888888',
    bgGray: '#acbbbf',
    lightPeriwinkle: '#F4F7FB',
    greyishBrown: '#3c3c3c',
    greyishBrown2: '#3d3d3d',
    veryLightPink: '#acacac',
    veryLightPink2: '#e5e4e4',
    electricBlue: '#0f52fa',
    paleBlue: '#d7f1f6',
    aquamarine: '#06d6a0',
    redBright: '#f96578'
  },
  fonts: {
    title: 'QuincyCF Bold',
    subtitle: 'ModernEra ExtraBold',
    content: 'ModernEra Regular',
    loader: 'NotoSans'
  },
  fontSizes: {
    xxLarge: '36px',
    xLarge: '34px',
    large: '24px',
    medium: '20px',
    small: '16px',
    xSmall: '12px',
  },
}

export default themeData
