import styled from 'styled-components'

const Box = styled.div`
  width: ${props => props.width}%;
  @media only screen and (min-width: 500px) {
    width: 80%;
  }
  @media only screen and (min-width: 768px) {
    width: 80%;
  }
  background: ${props => props.theme.colors[props.color]};
  ${({ center }) => center && `
    display: flex;
    justify-content: center;
    margin: 0 auto;
  `}
${({ notFound }) => notFound && `
   margin-top: 20%;
  `}
`

export default Box
