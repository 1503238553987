import styled from 'styled-components'

export const Container = styled.div`
  margin: 40px auto;
  width: 59%;
`

export const Wrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;
`

export const WrapperText = styled.div`
  width: 100%;
  text-align: left;
  font-size: 21px;
  color: #3c3c3c;
`

export const ImageWrapper = styled.div`
  width: 67%;
  display: flex;
  justify-content: center;
  align-items: center;
`
