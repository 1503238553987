/* eslint-disable require-jsdoc */
/* eslint-disable no-process-env */
import React from 'react'
import { Switch, Route } from 'react-router-dom'
import {
  Home
} from '../screens'
import { useTracking } from '../utils/tracking'

const Router = () => {
  useTracking(process.env.REACT_APP_GA_ID)
  return (
    <Switch>
        <Route
            exact
            path="/patient/:customerId/:version"
            component={Home}
        />  <Route
            exact
            path="/patient/:customerId"
            component={Home}
        />
      <Route
        path="/"
        component={Home}
      />

    </Switch>
  )
}

export default Router
