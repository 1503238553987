import styled from 'styled-components'

const Container = styled.div`
  width: 100%;
  height: auto;
  max-height: ${props => props.maxHeight}px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  margin-top: ${props => props.marginV}px;
  margin-bottom: ${props => props.marginV}px;
    ${({ start }) => start
  && `
   justify-content: start;
  `}
`

export default Container
