import styled from 'styled-components'

const IconCarousel = styled.img`
  @-moz-keyframes spin { 100% { -moz-transform: rotate(360deg); } }
  @-webkit-keyframes spin { 100% { -webkit-transform: rotate(360deg); } }
  @keyframes spin { 100% { -webkit-transform: rotate(360deg); transform:rotate(360deg); } }
   ${({ loading }) => loading
  && `
  -webkit-animation:spin 4s linear infinite;
  -moz-animation:spin 4s linear infinite;
  animation:spin 4s linear infinite;
`}
   ${({ loader }) => loader
  && `
  height: 36px;
  margin: 30px;
`}
`

export default IconCarousel
