import styled from 'styled-components'
import ReactPlayer from 'react-player'

const Video = styled(ReactPlayer)`
  margin: 0 auto;
  @media only screen and (min-width: 321px) {
  margin: 20px auto;
  }
`

export default Video
