import styled from 'styled-components'

const Wrapper = styled.div`
  margin: 0 auto;
  width: 100%;
  max-width: 543px;
  height: 100vh;
  background-color: ${props => props.theme.colors[props.color]};
  ${({ success }) => success
    && `
      background-image: linear-gradient(#e5e4e4, white, white, white, white);
    `
}
  ${({ notFound }) => notFound
    && `
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    `
}
`

export default Wrapper
