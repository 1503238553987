/**
 * @param {number} bytes - the size of file
 * @return {string} - the size in human read
 */
const bytesToSize = (bytes) => {
  const sizes = ['Bytes',
    'KB',
    'MB',
    'GB',
    'TB']
  if (bytes === 0) return '0 Byte'
  const i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)))
  return `${Math.round(bytes / Math.pow(1024, i), 2)} ${sizes[i]}`
}

export default bytesToSize
