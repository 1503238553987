import React from 'react'
import { BrowserRouter } from 'react-router-dom'
import { SnackbarProvider } from 'notistack'
import Theme from './theme'
import Router from './router/router'

function App() {
  return (
    <Theme>
      <SnackbarProvider maxSnack={3}>
        <BrowserRouter>
          <Router />
        </BrowserRouter>
      </SnackbarProvider>
    </Theme>
  )
}

export default App
