import styled from 'styled-components'

const PhotoCard = styled.label`
  position: relative;
  width: 89%;
  height: 356px;
  margin: 20px auto 30px;
  box-shadow: 0 7px 9px 0 rgba(0, 0, 0, 0.16);
  background-color: ${props => (props.loading ? props.theme.colors.veryLightPink2 : props.theme.colors.paleBlue)};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  
    @media only screen and (min-width: 540px) {
    width: 72%;
    height: 456px;
  }

  ${({ tryAgain }) => tryAgain && `
    background-color: #e5e4e4
  `
}
`

export default PhotoCard
