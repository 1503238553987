import styled from 'styled-components'

export const Container = styled.div`
  margin: 20px 0 0 0;
  width: 100%;
  max-height: 282px;
  border-radius: 35px 35px 0 0;
  background: ${props => props.theme.colors.lightPeriwinkle};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
`

export const ContainerRed = styled.div`
  width: 100%;
  height: 123px;
  background-color: #fce1e4;
  box-shadow: 0 17px 19px 0 #f1efef;
  margin-bottom: 60px;
`

export const WrapperRed = styled.div`
  margin: 0 auto;
  width: 90%;
  height: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;
  font-size: 16px;
  text-align: left;
  color: #292929;
`

export const ImageWrapper = styled.div`
  width: 40%;
  display: flex;
  justify-content: center;
  align-items: center;
`
export const Image = styled.img`
  width: 32px;
`
