import styled from 'styled-components'

const IconButton = styled.div`
  position: absolute;
  bottom: 10px;
  right: 10px;
  width: 63px;
  height: 63px;
  border-radius: 31.5px;
  -webkit-backdrop-filter: blur(30px);
  backdrop-filter: blur(30px);
  box-shadow: 0 2px 4px 0 rgba(31, 50, 83, 0.13);
  background-color: ${props => (props.loading ? props.theme.colors.veryLightPink : props.theme.colors.electricBlue)};
  background-color: ${props => props.photo && props.theme.colors.aquamarine};
  background-color: ${props => props.tryAgain && props.theme.colors.redBright};
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
`

export default IconButton
