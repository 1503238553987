import React, {useEffect, useState} from 'react'
import instanceServer from "../../api/api";
import {LoaderPage} from "../../components";

const callApi = async (customerId) => {
  try {
    const {data} = await instanceServer.post('get-patient', { customerId })
    return data
  } catch (error){
    return error
  }
}


const Home = ({ match }) => {
  const [customerId] = useState(match.params.customerId)
  const imCandidateURL = process.env.REACT_APP_IM_CANDIDATE_URL
  const isProd = imCandidateURL.includes('soycandidato.mymoons.')
  const domain =  document.location.host
  let country = 'mx/'
  if (domain.includes('mymoons.co')) {
    country = 'co/'
  }
  useEffect( () => {
    callApi(customerId).then(data => {
      if (data && data.publicKey){
        const {publicKey, countryOps} = data
        if (isProd){
          if (countryOps === 'Colombia' || countryOps === 'colombia'){
            window.location.href = `${imCandidateURL}co/${publicKey}`
          } else {
            window.location.href = `${imCandidateURL}mx/${publicKey}`
          }
        } else {
          window.location.href =  `${imCandidateURL}/${publicKey}`
        }
      } else {
        window.location.href = isProd ? `${imCandidateURL}${country}` : imCandidateURL
      }

    })
  }, [])

  return <LoaderPage />
}

export default Home
