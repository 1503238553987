import React from 'react'
import { moon } from '../../assets/svg'
import './loaderPage.css'

const LoaderPage = () => (
  <div className="modal">
    <div className="heartbeat">
      <img
        height="100"
        width="100"
        src={moon}
        alt="Loader page"
      />
    </div>
  </div>
)

export default LoaderPage
