import React from 'react'
import { ThemeProvider } from 'styled-components'
import themeData from './theme-data'

/**
 * @param {React.FunctionComponent} children - The components of app.
 * @return {React.FunctionComponent} app wrapped in theme props
 */
const Theme = ({ children }) => (
  <ThemeProvider theme={themeData}>{children}</ThemeProvider>
)

export default Theme
