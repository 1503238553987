import styled from 'styled-components'

const Button = styled.button`
  -webkit-appearance:default-button;
  width: 84%;
  height: 59px;
  border-radius: 10px;
  background-color: ${props => (props.disabled ? props.theme.colors.brownGrey : props.theme.colors.electricBlue)};
  color: rgba(255, 255, 255, 0.84);
  border: none;
  margin-bottom: 37px;
  margin-top: 4px;
`

export default Button
