import axios from 'axios'

const { REACT_APP_URL_SERVER, REACT_APP_SERVER_USER, REACT_APP_SERVER_PASS } = process.env

const instanceServer = axios.create({
  baseURL: REACT_APP_URL_SERVER,
  headers: {
    Accept: 'application/json',
    Authorization: `Basic ${btoa(`${REACT_APP_SERVER_USER}:${REACT_APP_SERVER_PASS}`)}`
  }
})

export default instanceServer
